var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full flex flex-col h-full"},[(false)?_c('div',{staticClass:"flex sm:flex-row flex-col gap-3",class:{
      'px-5 py-3 md:pt-4': !_vm.paddingless
    }},[_c('div',{staticClass:"flex flex-col gap-1 sm:flex-1"},[_c('fw-heading',{attrs:{"size":'lg',"muted":true,"counter":_vm.loading ? 0 : _vm.channels.length,"counter-total":_vm.loading ? 0 : _vm.totalResults}},[_vm._v(" "+_vm._s(_vm.$t('channels'))+" ")])],1),_c('div',{staticClass:"flex gap-1 items-center"})]):_vm._e(),(false)?_c('div',{class:{
      'px-5 pb-3 md:px-4': !_vm.paddingless
    }},[_c('ContextualSearch',{attrs:{"type":"minimal","filter-options":_vm.filterOptions,"loading":_vm.loading,"applied-filters":_vm.appliedFilters,"show-filters":false},on:{"search":_vm.search}})],1):_vm._e(),_c('div',{staticClass:"flex-1 flex flex-col pt-3 h-full overflow-y-auto",class:{
      'px-5': !_vm.paddingless
    }},[(_vm.loading || (_vm.metrics.length == 0 && _vm.query))?_c('div',{staticClass:"text-sm text-gray-500 py-10 text-center flex justify-center items-center h-full"},[(_vm.loading)?_c('div',{staticClass:"animate-pulse"},[_vm._v("A carregar...")]):_c('div',[_vm._v(" Sem filtros correspondentes ")])]):_c('div',{staticClass:"h-full overflow-y-auto w-full flex flex-col gap-1"},_vm._l((_vm.metrics),function(channel){return _c('RecordChannel',{key:channel.id,attrs:{"selected":_vm.selected.includes(channel.key),"record":channel},nativeOn:{"click":function($event){return _vm.$emit('select', channel.key)}}})}),1)]),(_vm.totalPages > 1 && !_vm.loading)?_c('BlockPagination',{attrs:{"per-page":_vm.limit,"total":_vm.totalResults,"total-pages":_vm.totalPages,"current":_vm.page},on:{"update:current":function($event){_vm.page=$event},"page-changed":function($event){return _vm.pageChanged($event)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }