<template>
  <div class="w-full px-4 py-4">
    <div class="flex items-center gap-3 font-medium text-sm">
      <fw-icon-electricity-fill v-if="summaryKey == 'electricity_consumption'" class="h-5 w-5 text-gray-300" />
      <fw-icon-leaf v-else-if="summaryKey == 'electricity_production'" class="h-5 w-5 text-gray-300" />
      <fw-icon-water-fill v-else-if="summaryKey == 'water'" class="h-5 w-5 text-gray-300" />
      {{ $t(summaryKey) }}
    </div>
    <div class="flex gap-5 items-end">
      <div class="text-lg flex-1 mt-4">
        <div class="flex items-center gap-3 font-semibold">
          {{ currentValue }}{{ unit }}
          <div
            class=" font-semibold text-sm flex gap-2 items-center"
            :class="{
              'text-green-500': diference > 0,
              'text-red-500': diference < 0,
              'text-gray-300': diference == 0
            }"
          >
            <div
              v-if="diference != 0"
              class="h-5 w-5 flex flex-col items-center justify-center  bg-opacity-10 rounded-full"
              :class="{
                'bg-green-400': diference > 0,
                'bg-red-400': diference < 0
              }"
            >
              <fw-icon-arrow-up v-if="diference > 0" class="h-4 w-4 text-green-500" />
              <fw-icon-arrow-down v-else-if="diference < 0" class="h-4 w-4 text-red-500" />
            </div>
            {{ diference }}%
          </div>
        </div>
        <div class="text-xs text-gray-400 mt-3">{{ $t('in_comparison_with') }}<br />{{ $t(timeFrame) }}</div>
      </div>
      <MiniGraph :height="80" :width="80" :color="color" :values="last10points" />
    </div>
  </div>
</template>
<script>
import MiniGraph from '../graphs/MiniGraph'
export default {
  name: 'BlockSummary',
  components: { MiniGraph },
  props: {
    summary: {
      type: Object
    },
    timeFrame: {
      type: String
    },
    color: {
      type: String,
      default: '#eb9234'
    },
    summaryKey: {
      type: String
    }
  },
  computed: {
    last10points() {
      let data = JSON.parse(JSON.stringify(this.data))
      return data.splice(-10)
    },
    currentSummary() {
      return this.summary[this.summaryKey]
    },
    diference() {
      return this.currentSummary['percentual_diff']
    },
    currentValue() {
      return this.currentSummary['current_total']
    },
    unit() {
      return this.currentSummary['unit']
    },
    data() {
      return this.currentSummary['data'].map(el => el.consumption)
    }
  }
}
</script>

<i18n>
  {
    "pt": {
      "in_comparison_with": "em comparação com",
      "week": "a semana passada",
      "day": "o dia anterior",
      "month": "o mês anterior",
      "year": "o ano anterior",
      "electricity_consumption": "Consumo de electricidade",
      "electricity_production": "Produção de electricidade",
      "water": "Consumo de água"
    },
    "en": {
      "in_comparison_with": "in comparison with",
      "week": "last week",
      "day": "last day",
      "month": "last month",
      "year": "last year",
      "electricity_consumption": "Electricity consumpttion",
      "electricity_production": "Electricity production",
      "water": "Water consumption"
    }
  }
</i18n>
