import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import Api from '@/fw-modules/fw-core-vue/api/Api'

export default {
  base(config) {
    let api = Api(config)
    api.defaults.baseURL = FwEnvConfig.apiUrlAnalytics
    return api
  },

  baseUrl() {
    return FwEnvConfig.apiUrlAnalytics
  },

  async getCatalogue(catalogKey) {
    const response = await this.base().get(`/api/v1/catalogs/${catalogKey}`)
    return response.data
  },

  async getMetrics(catalogKey, metric_keys = [], dateRange = [], granularity = null) {
    //const response = await this.base().get(`/api/v1/catalogs/${catalogKey}/metrics/${app}/${metric}/${sub_metric}`)
    //&with_records=true
    var dataUrl = ''
    if (dateRange.length > 0) {
      dataUrl = `&min_index=${dateRange[0].toISOString().split('T')[0]}&max_index=${
        dateRange[1].toISOString().split('T')[0]
      }`
    }
    var granularityUrl = ''
    if (granularity != null) {
      granularityUrl = `&granularity=${granularity}`
    }
    const response = await this.base().get(
      `/api/v1/catalogs/${catalogKey}/metrics?metrics_keys=${metric_keys.join(',') + dataUrl + granularityUrl}`
    )
    return response.data
  },

  async getMyCatalogs() {
    const response = await this.base().get(`/api/v1/catalogs?as_owner=true`)
    return response.data
  },

  async getDashboard(id, params = {}) {
    const response = await this.base().get(`/api/v1/dashboards/${id}`, { params })
    return response.data
  }
}
