var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ChartLine',{attrs:{"chart-data":{
    labels: _vm.graphDays,
    datasets: [_vm.dataset]
  },"options":{
    legend: {
      display: false
    },
    animation: {
      duration: 0 // general animation time
    },
    hover: {
      animationDuration: 0 // duration of animations when hovering an item
    },
    responsiveAnimationDuration: 0, // animation duration after a resize
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0,
        hoverRadius: 0,
        hoverBorderWidth: 0,
        borderWidth: 0,
        hitRadius: 0,
        pointStyle: false
      },
      line: {
        tension: 0.5
      }
    },
    plugins: {
      legend: {
        display: false // Hide the legend
      },
      tooltip: {
        enabled: false // Hide the tooltip
      }
    },
    scales: {
      yAxes: [
        {
          display: false
        }
      ],
      xAxes: [
        {
          display: false
        }
      ]
    },
    datasetStrokeWidth: 1,
    tooltipFillColor: 'rgba(0,0,0,0.8)',
    tooltipFontStyle: 'bold'
  },"plugins":[],"height":_vm.height,"width":_vm.width}})
}
var staticRenderFns = []

export { render, staticRenderFns }