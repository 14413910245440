<template>
  <ChartLine
    :chart-data="{
      labels: graphDays,
      datasets: [dataset]
    }"
    :options="{
      legend: {
        display: false
      },
      animation: {
        duration: 0 // general animation time
      },
      hover: {
        animationDuration: 0 // duration of animations when hovering an item
      },
      responsiveAnimationDuration: 0, // animation duration after a resize
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        point: {
          radius: 0,
          hoverRadius: 0,
          hoverBorderWidth: 0,
          borderWidth: 0,
          hitRadius: 0,
          pointStyle: false
        },
        line: {
          tension: 0.5
        }
      },
      plugins: {
        legend: {
          display: false // Hide the legend
        },
        tooltip: {
          enabled: false // Hide the tooltip
        }
      },
      scales: {
        yAxes: [
          {
            display: false
          }
        ],
        xAxes: [
          {
            display: false
          }
        ]
      },
      datasetStrokeWidth: 1,
      tooltipFillColor: 'rgba(0,0,0,0.8)',
      tooltipFontStyle: 'bold'
    }"
    :plugins="[]"
    :height="height"
    :width="width"
  ></ChartLine>
</template>
<script>
import ChartLine from '@/fw-modules/fw-core-vue/ui/components/charts/ChartLine.vue'
export default {
  name: 'MiniGraph',
  components: {
    ChartLine
  },
  props: {
    numberDays: {
      type: Number,
      default: 7
    },
    values: {
      type: Array,
      default: () => [10, 17, 7, 12, 15, 20, 12]
    },
    height: {
      type: Number,
      default: 100
    },
    width: {
      type: Number,
      default: 100
    },
    color: {
      type: String,
      default: '#4c51bf'
    }
  },
  computed: {
    dataset() {
      let startColor = this.getGraphColor(0.5)
      let transparentColor = this.color + '00'
      let self = this
      return {
        label: 'hidden',
        data: this.values,
        borderColor: this.color,
        backgroundColor: function(context) {
          const chart = context.chart
          const { ctx, chartArea } = chart
          if (!chartArea) {
            // This case happens on initial chart load
            return
          }
          return self.getGradient(ctx, chartArea, startColor, transparentColor)
        }, //this.getGraphColor(d.title, 0.5),
        borderWidth: 2
      }
    },
    graphDays() {
      const days = []
      for (let i = 0; i < this.numberDays; i++) {
        days.push(i)
      }
      return days
    },
    graphData() {
      return {
        labels: this.graphDays,
        datasets: this.graphDatasets
      }
    }
  },
  methods: {
    getGraphColor(alpha = 1) {
      return this.color + (alpha < 1 ? Math.round(alpha * 255).toString(16) : '')
    },
    getGradient(ctx, chartArea, color, transparentColor) {
      const chartWidth = chartArea.right - chartArea.left
      const chartHeight = chartArea.bottom - chartArea.top
      let gradient = transparentColor
      let width = 0
      let height = 0
      if (!gradient || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        width = chartWidth
        height = chartHeight
        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
        gradient.addColorStop(0, transparentColor)
        //gradient.addColorStop(0.5, Utils.CHART_COLORS.yellow);
        gradient.addColorStop(1, color)
      }
      return gradient
    }
  }
}
</script>
