var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full px-4 py-4"},[_c('div',{staticClass:"flex items-center gap-3 font-medium text-sm"},[(_vm.summaryKey == 'electricity_consumption')?_c('fw-icon-electricity-fill',{staticClass:"h-5 w-5 text-gray-300"}):(_vm.summaryKey == 'electricity_production')?_c('fw-icon-leaf',{staticClass:"h-5 w-5 text-gray-300"}):(_vm.summaryKey == 'water')?_c('fw-icon-water-fill',{staticClass:"h-5 w-5 text-gray-300"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t(_vm.summaryKey))+" ")],1),_c('div',{staticClass:"flex gap-5 items-end"},[_c('div',{staticClass:"text-lg flex-1 mt-4"},[_c('div',{staticClass:"flex items-center gap-3 font-semibold"},[_vm._v(" "+_vm._s(_vm.currentValue)+_vm._s(_vm.unit)+" "),_c('div',{staticClass:"font-semibold text-sm flex gap-2 items-center",class:{
            'text-green-500': _vm.diference > 0,
            'text-red-500': _vm.diference < 0,
            'text-gray-300': _vm.diference == 0
          }},[(_vm.diference != 0)?_c('div',{staticClass:"h-5 w-5 flex flex-col items-center justify-center bg-opacity-10 rounded-full",class:{
              'bg-green-400': _vm.diference > 0,
              'bg-red-400': _vm.diference < 0
            }},[(_vm.diference > 0)?_c('fw-icon-arrow-up',{staticClass:"h-4 w-4 text-green-500"}):(_vm.diference < 0)?_c('fw-icon-arrow-down',{staticClass:"h-4 w-4 text-red-500"}):_vm._e()],1):_vm._e(),_vm._v(" "+_vm._s(_vm.diference)+"% ")])]),_c('div',{staticClass:"text-xs text-gray-400 mt-3"},[_vm._v(_vm._s(_vm.$t('in_comparison_with'))),_c('br'),_vm._v(_vm._s(_vm.$t(_vm.timeFrame)))])]),_c('MiniGraph',{attrs:{"height":80,"width":80,"color":_vm.color,"values":_vm.last10points}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }