<template>
  <div>
    <content-loader
      v-if="type == 'list'"
      :height="160"
      :width="400"
      :speed="2"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    >
      <rect x="62" y="0" rx="4" ry="4" width="117" height="6.4" />
      <rect x="62.66" y="12.98" rx="3" ry="3" width="352.75" height="20.42" />
      <rect x="61.66" y="55.98" rx="3" ry="3" width="352.75" height="20.42" />
      <rect x="62" y="45" rx="4" ry="4" width="117" height="6.4" />
      <circle cx="31.48" cy="20.06" r="15.45" />
      <circle cx="31.48" cy="65.06" r="15.45" />
    </content-loader>
    <content-loader
      v-else-if="type == 'row'"
      :height="50"
      :width="320"
      :speed="2"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width="320" height="50" />
    </content-loader>
    <content-loader
      v-else-if="type == 'sidebar'"
      :height="300"
      :width="200"
      :speed="2"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width="70" height="15" />
      <rect x="0" y="30" rx="3" ry="3" width="180" height="15" />
      <rect x="0" y="60" rx="3" ry="3" width="180" height="15" />
      <rect x="0" y="90" rx="3" ry="3" width="150" height="15" />
      <rect x="0" y="110" rx="3" ry="3" width="150" height="15" />
      <rect x="0" y="140" rx="3" ry="3" width="150" height="15" />
      <rect x="0" y="170" rx="3" ry="3" width="50" height="15" />
    </content-loader>
    <content-loader v-else :height="210" :width="400" :speed="2" primary-color="#f3f3f3" secondary-color="#ecebeb">
      <rect x="0" y="220" rx="3" ry="3" width="340" height="15" />
      <rect x="0" y="190" rx="3" ry="3" width="340" height="15" />
      <rect x="0" y="150" rx="3" ry="3" width="400" height="15" />
      <rect x="0" y="120" rx="3" ry="3" width="340" height="15" />
      <rect x="0" y="90" rx="3" ry="3" width="340" height="15" />
      <rect x="0" y="60" rx="3" ry="3" width="240" height="15" />
      <rect x="0" y="30" rx="3" ry="3" width="380" height="15" />
      <rect x="0" y="0" rx="3" ry="3" width="440" height="15" />
    </content-loader>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'
export default {
  name: 'ContentLoad',
  components: {
    ContentLoader
  },
  props: {
    type: String
  }
}
</script>
